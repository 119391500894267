export default {
  baseUrl: "https://api-omang4uat.cubettech.in",
  portalUrl: "https://omang4uat.cubettech.in",
  productionUrl: "https://omang.odineducation.net",
};
export const qbAdminId = "132060393";

const siteConfig = {
  siteName: "Omang",
  siteIcon: "ion-flash",
  footerText: "Omang ©2020",
};
const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault",
};
const language = "english";
export { siteConfig, language, themeConfig };
